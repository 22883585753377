import { useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import {
  MeshReflectorMaterial,
  Environment,
  ContactShadows,
  PerspectiveCamera,
} from "@react-three/drei";

import FrontLayer from "./FrontLayer/FrontLayer";
import CardsLayer from "./CardsLayer/CardsLayer";
// import { useControls } from 'leva';

export const Experience = ({ images, texts }) => {
  const { viewport } = useThree();
  const [rnd] = useState(() => Math.random());
  const p1 = useRef();

  // const { name, aNumber } = useControls({ name: 'World', aNumber: 0 })

  useFrame((state, dt) => {
    p1.current.rotation.x = 10 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 2;
    p1.current.rotation.y = 5 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3 + state.mouse.x) / 2;
  });

  return (
    <>

      <FrontLayer texts={texts} />
      
      {/* cards on floor */}
      <CardsLayer images={images} />

      <PerspectiveCamera 
        fov={viewport.aspect < 1 ? 75 : 70} 
        position={[0, viewport.aspect < 1 ? 0.1 : 0.15, 100]} 
        makeDefault 
      />

      {/* pointLights */}
      <group ref={p1}>
        <pointLight position={[0, 5, 5]} intensity={5} />
        {
        viewport.aspect > 1 
          && <pointLight position={[2, 8, -5.5]} intensity={5} /> 
          && <pointLight position={[2, -10, 5.7]} intensity={5} />
        }
      </group>

      {/* environment */}
      <Environment preset="city" />
      <color attach="background" args={["#211E1F"]} />
      <fog attach="fog" args={["#211E1F", 0, 15]} />
      <hemisphereLight intensity={0.5} />
      <ContactShadows
        resolution={256}
        frames={10}
        position={[0, -0.65, 0]}
        scale={10}
        blur={1}
        opacity={1}
        far={20}
      />

    </>
  );
};