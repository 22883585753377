import { createRoot } from "react-dom/client";
import "./styles.css";
import { App } from "./App";

const images = [
  // Front
  { 
    position: [0, 0, 0.5], 
    rotation: [0, 0, 0], 
    url: './images/1.jpg',
    title: "Коммунальная техника",
    text: "Смена 8 часов",
    price: "от 14000 ₽"
  },
  // Back
  { 
    position: [-0.85, 0, -0.2], 
    rotation: [0, 0, 0], 
    url: './images/2.jpg',
    title: "Экскаваторы",
    text: "Гусеничные (гидромолот), Колесные (гидромолот), Мини экскаваторы (гидромолот, гидробур)",
    price: "от 15000 ₽"
  },
  { 
    position: [0.85, 0, -0.2], 
    rotation: [0, 0, 0], 
    url: './images/3.jpg',
    title: "Автогрейдеры",
    text: "",
    price: "от 17000 ₽"
  },
  // Left
  {
    position: [-1.75, 0, 0.25],
    rotation: [0, Math.PI / 2.75, 0],
    url: './images/4.jpg',
    title: "Погрузчики",
    text: "Мини погрузчики, фронтальные, экскаваторные, вилочные, телескопические",
    price: "от 10000 ₽",
  },
  {
    position: [-2, 0, 1.5],
    rotation: [0, Math.PI / 2.5, 0],
    url: './images/5.jpg',
    title: "Асфальтоукладчики",
    text: " ",
    price: "от 25000 ₽",
  },
  {
    position: [-2.15, 0, 2.75],
    rotation: [0, Math.PI / 2.5, 0],
    url: './images/6.jpg',
    title: "Дорожные фрезы",
    text: " ",
    price: "от 30000 ₽",
  },
  {
    position: [-2.1, 0, 3.75],
    rotation: [0, Math.PI / 2.5, 0],
    url: './images/7.jpg',
    title: "Дорожные катки",
    text: " ",
    price: "от 10000 ₽",
  },
  // Right
  {
    position: [1.75, 0, 0.25],
    rotation: [0, -Math.PI / 2.75, 0],
    url: './images/8.jpg',
    title: "Бульдозеры",
    text: " ",
    price: "от 11000 ₽",
  },
  {
    position: [2, 0, 1.5],
    rotation: [0, -Math.PI / 2.5, 0],
    url: './images/9.jpg',
    title: "Автосамосвалы",
    text: " ",
    price: "от 10000 ₽",
  },
  {
    position: [2.15, 0, 2.75],
    rotation: [0, -Math.PI / 2.5, 0],
    url: './images/10.jpg',
    title: "Автокраны",
    text: " ",
    price: "от 12000 ₽",
  },
  {
    position: [2.1, 0, 3.75],
    rotation: [0, -Math.PI / 2.5, 0],
    url: './images/11.jpg',
    title: "Кран манипулятор",
    text: " ",
    price: "от 12000 ₽",
  },
];

const texts = {
  'line1': {
    text: 'Аренда спецтехники в Москве и МО',
  },
  'line2': {
    text: 'БЦ Легион 3, Киевская улица 7, корпус 2',
  },
  'line3': {
    text: '121059, Москва, Дорогомилово',
  },
  'phone': {
    text: '8-800-770-770-3',
    tel: '+78007707703',
  }
}

createRoot(document.getElementById("root")).render(
  <>
    <div className="app-wrapper">
      <App images={images} texts={texts} />
    </div>
  </>,
);
