import { Quaternion, Vector3 } from "three";
import { useEffect, useRef } from "react";
import { useThree } from "@react-three/fiber";
import { useRoute, useLocation } from "wouter";
import gsap from "gsap";

import Frame from "./Card";

export default function Cards({ images, q = new Quaternion(), p = new Vector3(), }) {
  const ref = useRef();
  const clicked = useRef();
  const [, params] = useRoute("/item/:id");
  const [, setLocation] = useLocation();
  const { camera, viewport } = useThree();

  useEffect(() => {
    clicked.current = ref.current.getObjectByName(params?.id);
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true);
      clicked.current.parent.localToWorld(p.set(0, 0, viewport.aspect < 1 ? 2.35 : 1.5));
      clicked.current.parent.getWorldQuaternion(q);
    } else {
      p.set(0, viewport.aspect < 1 ? 0.1 : 0.15, 6.5);
      q.identity();
    }
  });

  useEffect(() => {
    gsap.to(camera.position, {
      ...p,
      duration: 1
    });
    gsap.to(camera.quaternion, q);
  }, [p, q]);

  return (
    <group
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        console.log(e.object);
        setLocation(
          clicked.current === e.object ? "/" : "/item/" + e.object.name,
        )
      }}
      onPointerMissed={() => setLocation("/")}
    >
      {images.map((props) => {
        return (
          <Frame 
            key={props.url} 
            {...props} 
          />
        );
      },
      )}
    </group>
  );
}