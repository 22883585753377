import { MeshReflectorMaterial } from "@react-three/drei";

import Cards from "./Cards";

export default function CardsLayer({images}) {

  return (
    <group position={[0, -0.5, 0]}>
      <Cards 
        images={images} 
      />
      {/* Floor */}
      <mesh 
        name="Floor"
        receiveShadow 
        position={[0, -0.8, 0]} 
        rotation={[-Math.PI / 2, 0, 0]}
      >
        <planeGeometry args={[50, 50]} />
        <MeshReflectorMaterial
          name="FloorMaterial"
          blur={[300, 100]}
          resolution={1024}
          mixBlur={1}
          mixStrength={80}
          roughness={0.95}
          depthScale={1.2}
          minDepthThreshold={0.4}
          maxDepthThreshold={1.4}
          color="#070505"
          metalness={0.25}
        />
      </mesh>
    </group>
  );
}