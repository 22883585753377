import { Color, } from "three";
import { useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { useCursor, Image, Text, RoundedBox, } from "@react-three/drei";
import { useRoute } from "wouter";
import { easing } from "maath";
import getUuid from "uuid-by-string";
import { Flex, Box, } from '@react-three/flex';

const GOLDENRATIO = 1.63803398875;

export default function Card({ url, c = new Color(), ...props }) {
  const image = useRef();
  const frame = useRef();
  const [, params] = useRoute("/item/:id");
  const [hovered, hover] = useState(false);
  const [rnd] = useState(() => Math.random());
  const name = getUuid(url);
  const { title, text, price} = props;
  const isActive = params?.id === name;
  const textProps = {
    maxWidth: 0.8,
    anchorX: "left",
    anchorY: "top",
    fontSize: 0.05,
    font: "../fonts/Montserrat-Regular.ttf"
  }
  const positionLeft = -0.4;

  useCursor(hovered);
  useFrame((state, dt) => {
    // image.current.material.zoom = 1 + Math.sin(rnd * 100 + state.clock.elapsedTime / 10) / 2;
    easing.damp3( image.current.scale, 
      [
        0.85 * (!isActive && hovered ? 0.85 : 1),
        0.9 * (!isActive && hovered ? 0.905 : 1),
        1,
      ],
      0.1,
      dt,
    );
    easing.dampC(
      frame.current.material.color,
      hovered ? "orange" : "white",
      0.1,
      dt,
    );
  });
  
  return (
    <group {...props}>
      <mesh
        raycast={() => null}
        scale={[0.98, GOLDENRATIO / 1.9, 0.05]}
        position={[0, GOLDENRATIO /  1.85, 0.001]}
      >
        <boxGeometry />
        <meshStandardMaterial
          color="#fafafa"
          metalness={1}
          roughness={0.1}
          envMapIntensity={1}
        />
        <mesh
          ref={frame}
          raycast={() => null}
          scale={[0.98, 0.98, 0.98]}
          position={[0, 0, 0.1]}
        >
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} />
        </mesh>
        <Image
          raycast={() => null}
          ref={image}
          position={[0, 0, 0.9]}
          url={url}
        />
      </mesh>
      {/* card back */}
      <group
        position={[0, GOLDENRATIO / 2.5, 0]}
      >
        <RoundedBox
          name={name}
          onPointerOver={(e) => (e.stopPropagation(), hover(true))}
          onPointerOut={() => hover(false)}
          args={[1, GOLDENRATIO / 1.1, 0.05]}
          radius={0.025}
          smoothness={5}
          bevelSegments={5}
          creaseAngle={0.5}
          castShadow
        >
          <meshStandardMaterial
            color="#fafafa"
            metalness={1}
            roughness={0.25}
            envMapIntensity={0.5}
          />
        </RoundedBox>
        {/* 
        <RoundedBox
          args={[0.945, 0.001, 0.01]}
          radius={0.00025}
          smoothness={5}
          bevelSegments={5}
          creaseAngle={0.005}
          position={[0, 0.65, 0.05]}
        >
          <meshStandardMaterial
            color="#fafafa"
            metalness={1}
            roughness={0.25}
            envMapIntensity={0.5}
          />
        </RoundedBox>
        <RoundedBox
          args={[0.945, 0.001, 0.01]}
          radius={0.00025}
          smoothness={5}
          bevelSegments={5}
          creaseAngle={0.005}
          position={[0, -0.185, 0.05]}
        >
          <meshStandardMaterial
            color="#fafafa"
            metalness={1}
            roughness={0.25}
            envMapIntensity={0.5}
          />
        </RoundedBox> */}
      </group>

      {/* card text */}
      <Flex
        position={[positionLeft, GOLDENRATIO - 1.25, 0.035]}
        justifyContent="flex-start" 
        alignItems="flex-start"
        width="100%" 
      >
        <Box 
          dir="column" 
          minHeight="10%"
        >
          <Box 
            dir="row" 
            grow={1} 
            marginBottom={0.05}
          >
            <Text
              {...textProps}
              fontSize={0.065}
              // font={"../fonts/Montserrat-Bold.ttf"}
            >
              {title}
            </Text>
          </Box>
          <Box 
            dir="row"  
            grow={2} 
            marginBottom={0.04}
          >
            <Text
              {...textProps}
              fontSize={0.035}
            >
              {text}
            </Text>
          </Box>
          <Box 
            dir="row" 
            grow={3} 
          >
            <Text
              {...textProps}
              // font={"../fonts/Montserrat-Bold.ttf"}
              fontSize={0.05}
            >
              {price}
            </Text>
          </Box>
        </Box>
      </Flex>
    </group>
  );
}