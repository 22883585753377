import { useEffect, useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import {
  MeshTransmissionMaterial,
  RoundedBox,
  Text,
  useCursor,
} from "@react-three/drei";

import {LoopOnce} from 'three';

import Logo from "./Logo";
import Texts from "./Texts";
import gsap from "gsap";

export default function FrontLayer({ texts }) {
  const { viewport } = useThree();
  
  const matRef = useRef();
  const logo = useRef();
  const text0Ref = useRef();
  const text1Ref = useRef();
  const text2Ref = useRef();
  const text3Ref = useRef();
  const neonRef = useRef();
  const [hovered, hover] = useState(false);

  useEffect(() => {
    setTimeout(() => playLogoAnimation(), 100);
  }, []);

  function playLogoAnimation() {
    gsap.fromTo([
      text0Ref.current.position, 
      text1Ref.current.position, 
      text2Ref.current.position, 
      text3Ref.current.position,
    ], 
    {
      y: -1
    },
    {
      y: function(i) {
        if (i < 3) {
          return (-0.2 - i / 16)
        } else {
          return -0.5
        }
      },
      delay: 1,
      stagger: 0.2,
      onComplete: () => {
        // animate neon on phone
        gsap.timeline({
          repeat: -1,
          yoyo: true
        }).fromTo(neonRef.current, {
          envMapIntensity: 1,
        }, {
          envMapIntensity: 6,
          duration: 3.5
        });
      }
    });
    const entries = Object.entries(logo.current)
    entries.forEach((action) => {
      if (action[0] === 'logo_root') {
        setTimeout(() => {
          action[1].reset();
          action[1].timeScale = 0.35;
          action[1].play();
        }, 1000);
        
        return;
      }
      action[1].reset();
      action[1].clampWhenFinished = true;
      action[1].timeScale = 1.5;
      action[1].setLoop(LoopOnce, 1);
      action[1].play();
    });

    return () => {
      entries.forEach((action) => {
        if (action[0] === 'logo_root') {
          return;
        }
        action[1].timeScale = -1.5;
        action[1].paused = false;
      });
    }
  }

  const playLogoAnimationReverse = () => {
    const entries = Object.entries(logo.current)

    entries.forEach((action) => {
      action[1].play();
    })
    return () => {
      entries.forEach((action) => {
        action[1].timeScale = 1.5;
        action[1].paused = false;
      });
    }
  }

  useCursor(hovered);
  const candara = "../fonts/CANDARA.TTF";
  const montserrat = "../fonts/Montserrat-Regular.ttf";
  // console.log(viewport.aspect);
  return (
    <>
      {/* Logo */}
      <Logo 
        ref={logo} 
        scale={viewport.aspect < 1 ? 0.06 : 0.085} 
        position={[-0.02, viewport.aspect < 1 ? 0.16 : 0.2, 5.25]} 
      />

      {/* Texts */}
      <Text
        ref={text0Ref}
        position={[0, -0.2, 5.25]}
        fontSize={0.048}
        textAlign="center"
        font={candara}
      >
        <meshStandardMaterial
          color="#fafafa"
          metalness={1}
          roughness={0.15}
          envMapIntensity={5}
        />
        {texts.line1.text}
      </Text>
      <Text
        ref={text1Ref}
        position={[0, -0.4, 5.25]}
        fontSize={0.038}
        textAlign="center"
        font={candara}
      >
        <meshStandardMaterial
          color="#fafafa"
          metalness={1}
          roughness={0.15}
          envMapIntensity={2}
        />
        {texts.line2.text}
      </Text>
      <Text
        ref={text2Ref}
        position={[0, -0.45, 5.25]}
        fontSize={0.04}
        textAlign="center"
        font={candara}
      >
        <meshStandardMaterial
          color="#fafafa"
          metalness={1}
          roughness={0.15}
          envMapIntensity={2}
        />
        {texts.line3.text}
      </Text>
      <Text
        ref={text3Ref}
        position={[0, -0.54, 5.25]}
        fontSize={0.08}
        textAlign="center"
        font={montserrat}
        onClick={() => {
          window.open('tel:' + texts.phone.tel, '_self');
        }}
        onPointerOver={(e) => (e.stopPropagation(), hover(true))}
        onPointerOut={() => hover(false)}
      >
        <meshStandardMaterial
          ref={neonRef}
          color="#fafafa"
          metalness={1}
          roughness={0.15}
          envMapIntensity={1}
        />
        {texts.phone.text}
      </Text>
      
      {/* glass */}
      <RoundedBox
        name="glass"
        args={[10, 10, 0.5]}
        radius={0.03}
        smoothness={1}
        bevelSegments={1}
        creaseAngle={0.35}
        position={[0, 0, 4.65]}
      >
        <MeshTransmissionMaterial 
          ref={matRef}
          samples={10}
          resolution={viewport.aspect < 1 ? 512 : 1024}
          transmission={0.98}
          clearcoat={1}
          clearcoatRoughness={0.95}
          thickness={0.02}
          chromaticAberration={0.15}
          anisotropy={0.3}
          roughness={0.65}
          distortion={0.35}
          distortionScale={0}
          temporalDistortion={0}
          ior={1.5}
          color={"#795D62"}
          gColor={"#1b191a"}
          shadow={"#211E1F"}
        />
      </RoundedBox>

    </>
  );
};