import { useEffect, useRef, forwardRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

const Logo = forwardRef(function (props, ref) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/logo.glb");
  const logoAnimations = useAnimations(animations, group);

  useEffect(() => {
    materials.neon.emissiveIntensity = 5;
    materials.letters.opacity = 0;
  }, [materials])

  useEffect(() => {
    ref.current = logoAnimations.actions;
  }, [logoAnimations.actions]);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="letters" position={[0, -3.958, 0]} scale={[1, 1, 0.01]}>
          <mesh
            name="letter_1"
            castShadow
            receiveShadow
            geometry={nodes.letter_1.geometry}
            material={materials.letters}
            position={[-4.629, 0.837, 0.013]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={1.006}
          />
          <mesh
            name="letter_2"
            castShadow
            receiveShadow
            geometry={nodes.letter_2.geometry}
            material={materials.letters}
            position={[-3.457, 0.837, 0.013]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[0.967, 0.967, 0.838]}
          />
          <mesh
            name="letter_3"
            castShadow
            receiveShadow
            geometry={nodes.letter_3.geometry}
            material={materials.letters}
            position={[-2.696, 0.837, 0.013]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[0.976, 0.976, 0.846]}
          />
          <mesh
            name="letter_4"
            castShadow
            receiveShadow
            geometry={nodes.letter_4.geometry}
            material={materials.letters}
            position={[-1.518, 0.837, 0.013]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[0.995, 0.995, 0.862]}
          />
          <mesh
            name="letter_5"
            castShadow
            receiveShadow
            geometry={nodes.letter_5.geometry}
            material={materials.letters}
            position={[-0.185, 0.837, 0.013]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[1.004, 1.004, 0.87]}
          />
          <mesh
            name="letter_6"
            castShadow
            receiveShadow
            geometry={nodes.letter_6.geometry}
            material={materials.letters}
            position={[1.308, 0.837, 0.013]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[1.004, 1.004, 0.87]}
          />
          <mesh
            name="letter_7"
            castShadow
            receiveShadow
            geometry={nodes.letter_7.geometry}
            material={materials.letters}
            position={[3.579, 0.837, 0.013]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[0.981, 0.981, 0.85]}
          />
          <mesh
            name="letter_8"
            castShadow
            receiveShadow
            geometry={nodes.letter_8.geometry}
            material={materials.letters}
            position={[5.208, 0.837, 0.013]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[1.004, 1.004, 0.87]}
          />
          <mesh
            name="neon"
            castShadow
            receiveShadow
            geometry={nodes.neon.geometry}
            material={materials.neon}
            position={[-0.001, 0.832, 0.001]}
            scale={1.087}
          />
        </group>
        <group name="logo_root">
          <group name="logo" position={[0, -3.775, 0]} scale={0.025}>
            <mesh
              name="logo_1"
              castShadow
              receiveShadow
              geometry={nodes.logo_1.geometry}
              material={materials.logo}
              position={[0.012, 108.63, 16.179]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={[0, 0, 0.002]}
            />
            <mesh
              name="logo_2"
              castShadow
              receiveShadow
              geometry={nodes.logo_2.geometry}
              material={materials.logo}
              position={[0.359, 87.222, 16.184]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={[0, 0, 0.003]}
            />
            <mesh
              name="logo_3"
              castShadow
              receiveShadow
              geometry={nodes.logo_3.geometry}
              material={materials.logo}
              position={[0.359, 60.962, 16.345]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={[0, 0.004, 0.025]}
            />
            <mesh
              name="logo_4"
              castShadow
              receiveShadow
              geometry={nodes.logo_4.geometry}
              material={materials.logo}
              position={[0.038, 60.583, 15.493]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={[0.012, 0.009, 0]}
            />
            <mesh
              name="logo_5"
              castShadow
              receiveShadow
              geometry={nodes.logo_5.geometry}
              material={materials.logo}
              position={[18.565, 60.583, 9.497]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={[0.012, 0.009, 0]}
            />
            <mesh
              name="logo_6"
              castShadow
              receiveShadow
              geometry={nodes.logo_6.geometry}
              material={materials.logo}
              position={[32.602, 60.583, 3.8]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={[0.012, 0.009, 0]}
            />
          </group>
        </group>
      </group>
    </group>
  );
});

// useGLTF.preload("./logo.glb");
export default Logo;