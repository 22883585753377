import {Suspense, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Effects } from "./components/Effects";
import { LoadingScreen } from "./components/LoadingScreen";
import { Experience } from "./components/Experience";

export const App = ({ images, texts }) => {
  const [start, setStart] = useState(false);
  
  useEffect(() => {
    if (start) {
      
    }
  }, [start]);

  return (
    <>
      <Canvas
        shadows
        dpr={[1, 1.5]}
        camera={{ fov: 70, position: [0, 2, 3.5] }}
      >
        <Suspense fallback={null}>
          {start && <Experience images={images} texts={texts} />}
        </Suspense>
        <Effects />
      </Canvas>
      <LoadingScreen 
        started={start} 
        onStarted={() => setStart(true)} 
        texts={texts}
      />
    </>
  );
};
